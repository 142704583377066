const initialState = {
    data: [],
  }
  
  const MeetingDataListReducer = (state = initialState, action) => {
   
    switch (action.type) {
      case "GET_MEETING_DATA":
        return {
          ...state,
          data: action.data,
        }
      default:
        return state
    }
  }
  
  export default MeetingDataListReducer
  