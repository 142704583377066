import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import dataList from "./meetings"
import meetingDataList from "./meeting"

import projectDataList from "./projects"
import tasksDataList from "./tasks"

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList: dataList,
  meetingDataList:meetingDataList,
  projectDataList:projectDataList,
  tasksDataList:tasksDataList
})

export default rootReducer
