import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "dashboard",
    title: "لوحة القيادة",
    type: "collapse",
    icon: <Icon.Home size={20} />,
    children: [
      {
        id: "analyticsDash",
        title: "تحليل البيانات",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/"
      },

    ]
  },

  {
    id: "projects",
    title: "المشاريع",
    type: "item",
    icon: <Icon.Briefcase size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/projects"
  },
  {
    id: "meetings",
    title: "الاجتماعات",
    type: "item",
    icon: <Icon.Grid size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/meetings"
  },
  {
    id: "tasks",
    title: "المهام",
    type: "collapse",
    icon: <Icon.CheckSquare size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "myTasks",
        title: "مهامي",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tasks",
      },
      {
        id: "relatedTasks",
        title: "مهام ذات صلة",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/related-tasks"
      },
    ]
  },
  {
    id: "notifications",
    title: "الإشعارات",
    type: "item",
    icon: <Icon.Bell size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/notifications"
  },
]

export default navigationConfig
